export const releaseNotes = [
  {
    release_date: "February 13, 2025",
    version: "1.4.2",
    notes: {
      1: "Fix bug in generating amazon orders excel report.",
    },
  },
  {
    release_date: "February 8, 2025",
    version: "1.4.1",
    notes: {
      1: "Update refund list to include Sage Reference Number.",
    },
  },
  {
    release_date: "February 2, 2025",
    version: "1.4.0",
    notes: {
      1: "Optimize Amazon refund transactions.",
      2: "Get Amazon transaction data, breakdowns, and totals.",
      3: "Associate transactions with orders and financial event groups.",
      4: "Search and export refund transactions.",
    },
  },
  {
    release_date: "January 16, 2025",
    version: "1.3.1",
    notes: {
      1: "Include shipping data in Amazon orders.",
      2: "Add reconciliation of Amazon orders with date.",
      3: "Include export to excel for Amazon orders.",
    },
  },
  {
    release_date: "January 11, 2025",
    version: "1.3.0",
    notes: {
      1: "Initial release of Amazon order data, including tax calculations.",
    },
  },
  {
    release_date: "October 23, 2024",
    version: "1.2.0",
    notes: {
      1: "Update sales report queries to account for sales credits.",
      2: "Fix bug with sales invoices not showing.",
      3: "Update sales invoice list to include sales credit invoices.",
    },
  },
  {
    release_date: "October 22, 2024",
    version: "1.1.9",
    notes: {
      1: "Update customer list so sales show fiscal YTD.",
      2: "Modify sales performance report to account for sales credits."
    },
  },
  {
    release_date: "October 2, 2024",
    version: "1.1.8",
    notes: {
      1: "New sales reports.",
      2: "Reformatting of sales reports.",
    },
  },
  {
    release_date: "September 4, 2024",
    version: "1.1.7",
    notes: {
      1: "Fix customer search for sales by category report.",
    },
  },
  {
    release_date: "August 19, 2024",
    version: "1.1.6",
    notes: {
      1: "Fix bug in create sales call on Company Page, Contact tab.",
    },
  },
  {
    release_date: "August 13, 2024",
    version: "1.1.5",
    notes: {
      1: "Update website search for customer locations, online retailers.",
      2: "Update sales report - Customers by Cateogry report.",
    },
  },
  {
    release_date: "August 7, 2024",
    version: "1.1.4",
    notes: {
      1: "Update sales call entry on Company Detail screen.",
      2: "Resolve bug with save on User Role.",
      3: "Add new sales report. Work in progress.",
      4: "Get ship to addresses on customer detail page with show on website checkbox.",
    },
  },
  {
    release_date: "July 15, 2024",
    version: "1.1.3",
    notes: {
      1: "Streamline and sales call input form on the sales call screen.",
    },
  },
  {
    release_date: "June 3, 2024",
    version: "1.1.2",
    notes: {
      1: "Disable save button on sales call form when no category is selected.",
    },
  },
  {
    release_date: "May 3, 2024",
    version: "1.1.1",
    notes: {
      1: "Fix invoice search on customer detail page.",
      2: "Show correct invoice number on customer invoice list.",
      3: "Allow file upload on service request in all scenarios.",
    },
  },
  {
    release_date: "March 12, 2024",
    version: "1.1",
    notes: {
      1: "Permissions bug in sales commission report, customer, contact.",
      2: "Merge prospect function.",
      3: "Supplier sales history report.",
    },
  },
  {
    release_date: "March 5, 2024",
    version: "1.0.6",
    notes: {
      1: "Permissions bug in customer menu.",
      2: "Fix bug with sales call showing prospect star.",
      3: "Update report permissions.",
      4: "Fix minor calculation bug in sales target report when sales rep does not have targets.",
    },
  },
  {
    release_date: "March 5, 2024",
    version: "1.0.5",
    notes: {
      1: "Fix bug with packing slip generation.",
    },
  },
  {
    release_date: "February 29, 2024",
    version: "1.0.4",
    notes: {
      1: "Sales commission reports.",
      2: "Update the sales target report to address chanages in field names.",
      3: "Modify menu and persmissions on rental invoice report, sales target report.",
      4: "Minor bug fixes - sales performance, service request.",
      5: "Create base sales history report.",
      6: "Depot inventory report.",
    },
  },
  {
    release_date: "February 2, 2024",
    version: "1.0.3",
    notes: {
      1: "Fix bug - user roles not able to see rental invoice report.",
      2: "Modify font size on packing slip.",
    },
  },
  {
    release_date: "January 29, 2024",
    version: "1.0.2",
    notes: {
      1: "Delete a prospect record. Delete a contact record.",
      2: "List related companies on a record. Go to related company record.",
      3: "Update Customer sales history report.",
    },
  },
  {
    release_date: "January 25, 2024",
    version: "1.0.1",
    notes: {
      1: "Fix bug updating a Service Request.",
      2: "Update the sales target report to address chanages in field names.",
      3: "Modify menu and persmissions on rental invoice report, sales target report.",
    },
  },
  {
    release_date: "January 19, 2024",
    version: "1.0.0",
    notes: {
      1: "Initial production release.",
    },
  },
]
